
import EventBus from "@/assets/js/EventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  filters: {
    https: (url: string) => {
      if (url) {
        return url.replace("http:", "https:");
      }
    },
  },
})
export default class gImage extends Vue {
  @Prop() private src!: string;
  @Prop({ type: Boolean, default: false }) private resizable!: boolean;
  private showDefault = false;
  private isActive = false;
  private isScale = false;

  @Watch("src")
  onSrcChange(value: string) {
    this.showDefault = false;
  }

  handleError(): void {
    this.showDefault = true;
  }

  previewImage(): void {
    this.isActive = !this.isActive;
    this.$socket?.dispatchEvent("previewImage", this.isActive);
  }

  scaleImage(e: MouseEvent): void {
    if (!this.isActive) {
      return;
    }

    this.isScale = !this.isScale;
    if (!this.isScale) {
      return;
    }

    let image = this.$refs.picture as HTMLImageElement;
    let width = document.body.clientWidth || window.innerWidth;
    let height = document.body.clientHeight || window.innerHeight;

    let scaleArr = ["left top", "right top", "right bottom", "left bottom"];
    let axisX = e.clientX < width / 2 ? "left" : "right";
    let axisY = e.clientY < height / 2 ? "top" : "bottom";

    let index = scaleArr.indexOf(axisX + " " + axisY) + 3;
    image.style.transformOrigin = scaleArr[index % 4];
  }

  handleMessage(): void {
    EventBus.$on("previewImage", (isActive: boolean) => {
      this.isActive = isActive;
    });
  }

  mounted() {
    if (this.resizable) {
      this.handleMessage();
    }
  }
}
